<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <h2 class="text-lg font-semibold">Roles & Permission</h2>
    </div>

    <!-- Search and Actions -->
    <div class="">
      <div class="flex justify-end mb-4">
        <button class="btn btn-blue ml-auto" @click="openCreateRole">
          Create Roles
        </button>
      </div>
      <div class="w-3/4">
        <search-form
          v-model="searchQuery"
          placeholder="Search for staff name, email"
          @submit="fetchUsers"
        />
      </div>

      <!-- <button class="btn btn-gray-outline" @click="openFilters">Filters</button> -->
      <!-- <button
        class="btn btn-gray-outline flex items-center"
        @click="openAddRole"
      >
        Add role <ion-icon name="add-outline" class="ml-2"></ion-icon>
      </button> -->
    </div>

    <!-- Table -->
    <datatable
      ref="table"
      :ajax="true"
      :ajax-pagination="true"
      :url="usersUrlWithQuery"
      :query="searchQuery"
      :columns="columns"
      :class-name="rowClass"
    >
      <template v-slot:td-1="{ item: user }">
        <div class="text-sm font-semibold">{{ user.row.name }}</div>
      </template>

      <template v-slot:td-2="{ item: user }">
        <div class="text-sm text-gray-600">{{ user.row.description }}</div>
      </template>
      <template v-slot:td-3="{ item: user }">
        <div class="text-sm">
          {{ user.row.permissions?.length }} ({{
            user.row.permissions?.slice(0, 3).join(', ')
          }}){{ user.row.permissions?.length > 3 ? '...' : '' }}
        </div>
      </template>
      <template v-slot:td-4="{ item: user }">
        <div class="flex gap-2">
          <button
            class="btn btn-blue text-xs"
            @click.prevent="openModal(user.row)"
          >
            Edit Role
          </button>
        </div>
      </template>
    </datatable>

    <!-- modal edit -->
    <modal
      ref="roleModal"
      className="w-11/12 sm:w-3/5 lg:w-4/5 p-6 rounded-md shadow-lg bg-white  overflow-auto"
    >
      <template>
        <div class="flex justify-between items-center border-b pb-4">
          <h2 class="text-xl font-semibold">Edit Role</h2>
        </div>

        <!-- Role Input -->
        <div class="my-4 flex items-center gap-4">
          <label class="text-lg font-medium">Role:</label>
          <input
            v-model="roleName"
            type="text"
            class="w-full border p-2 rounded-md focus:outline-blue-500"
          />
        </div>

        <!-- Permissions Section -->
        <div class="my-4">
          <h3 class="text-lg font-semibold">All Permissions</h3>
          <div class="grid grid-cols-3 gap-2 mt-2 h-full">
            <button
              v-for="(perm, index) in permissions"
              :key="index"
              @click="togglePermission(perm)"
              class="p-2 rounded-md text-white text-11"
              :class="{
                'bg-blue-500': hasPermission(perm),
                'bg-blue-300': !hasPermission(perm),
              }"
            >
              {{ perm }}
            </button>
          </div>
        </div>

        <!-- Actions -->

        <div class="mt-auto">
          <div class="flex justify-end gap-2 mt-6">
            <button
              @click="undoChanges"
              class="px-4 py-2 border border-gray-500 text-gray-600 rounded-md hover:bg-gray-300"
            >
              Undo Changes
            </button>
            <button
              @click="saveChanges"
              class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Save Changes
            </button>
          </div>
        </div>
      </template>
    </modal>

    <!-- Create Role Modal -->
    <modal
      ref="createroleModal"
      className="w-11/12 sm:w-3/5 lg:w-4/5 p-6 rounded-md shadow-lg bg-white overflow-auto"
    >
      <template>
        <div class="flex justify-between items-center border-b pb-4">
          <h2 class="text-xl font-semibold">Create Role</h2>
        </div>

        <!-- Role Name Input -->
        <div class="my-4">
          <label class="text-lg font-medium">Role:</label>
          <input
            v-model="roleName"
            type="text"
            class="w-full border p-2 rounded-md focus:outline-blue-500"
            placeholder="Enter role name"
          />
        </div>

        <!-- Role Description -->
        <div class="my-4">
          <label class="text-lg font-medium">Description:</label>
          <textarea
            v-model="roleDescription"
            class="w-full border p-2 rounded-md focus:outline-blue-500 h-24 resize-none"
            placeholder="Enter role description"
          ></textarea>
        </div>

        <!-- Permissions Section -->
        <div class="my-4">
          <h3 class="text-lg font-semibold">All Permissions</h3>
          <div class="grid grid-cols-3 gap-2 mt-2">
            <button
              v-for="(perm, index) in permissions"
              :key="index"
              @click="togglePermission(perm)"
              class="p-2 rounded-md text-white text-sm"
              :class="{
                'bg-blue-500': hasPermission(perm),
                'bg-blue-300': !hasPermission(perm),
              }"
            >
              {{ perm }}
            </button>
          </div>
        </div>

        <!-- Actions -->
        <div class="flex justify-end gap-2 mt-6">
          <button
            @click="closeCreateRoleModal"
            class="px-4 py-2 border border-gray-500 text-gray-600 rounded-md hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            @click="createRole"
            class="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
          >
            Create Role
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'RolesPermissions',
  data() {
    return {
      searchQuery: '',
      columns: [
        { name: 'name', th: 'Name', sortable: true },

        { name: 'description', th: 'Description', sortable: false },
        { name: 'permissions', th: 'Permissions', sortable: false },
        { name: 'actions', th: 'Actions', sortable: false },
      ],
      roleName: '',
      roleDescription: '',
      selectedPermissions: [],
      permissions: [],
      originalRole: '',
      originalPermissions: [],
      roleId: '',
    };
  },
  methods: {
    fetchUsers() {
      this.$refs.table.loadAjaxData();
    },

    async fetchPermissions() {
      try {
        const response = await this.$get({
          url: `${this.$baseurl}/auths/v1/admin/all-permissions`,
          headers: this.headers,
        });
        this.permissions = response?.response?.data || [];
      } catch (error) {
        console.error('Error fetching permissions:', error);
      }
    },
    rowClass(user) {
      return user.selected ? 'bg-gray-100' : '';
    },
    selectUser(user) {
      console.log('Selected user:', user);
    },

    addPermissions(user) {
      console.log('Adding permissions for:', user);
    },
    openFilters() {
      console.log('Opening filters...');
    },
    openAddRole() {
      console.log('Opening add role modal...');
    },
    openCreateRole() {
      this.roleName = '';
      this.roleDescription = '';
      this.selectedPermissions = [];
      this.$refs.createroleModal.open();
    },

    openModal(user) {
      this.roleName = user.name || '';
      this.originalRole = user.name || '';
      this.roleDescription = user.description || '';

      this.roleId = user.id;

      // Ensure permissions is always an array
      this.selectedPermissions = Array.isArray(user.permissions)
        ? [...user.permissions]
        : [];
      this.originalPermissions = Array.isArray(user.permissions)
        ? [...user.permissions]
        : [];

      this.$refs.roleModal.open();
    },
    closeModal() {
      this.$refs.roleModal.close();
    },
    closeCreateRoleModal() {
      this.$refs.createroleModal.close();
    },

    async createRole() {
      if (!this.roleName.trim()) {
        return;
      }

      try {
        const payload = {
          name: this.roleName,
          description: this.roleDescription,
          permissions: this.selectedPermissions,
        };

        await this.$post({
          url: `${this.$baseurl}/auths/v1/roles`,
          data: payload,
          headers: this.headers,
        });

        this.fetchUsers();
        this.closeCreateRoleModal();
      } catch (error) {
        console.error('Error creating role:', error);
      }
    },
    togglePermission(permission) {
      if (this.selectedPermissions.includes(permission)) {
        this.selectedPermissions = this.selectedPermissions.filter(
          (p) => p !== permission
        );
      } else {
        this.selectedPermissions.push(permission);
      }
    },
    hasPermission(permission) {
      return this.selectedPermissions.includes(permission);
    },
    undoChanges() {
      this.roleName = this.originalRole;
      this.selectedPermissions = [...this.originalPermissions];

      this.closeModal();
    },

    async saveChanges() {
      if (!this.roleId) {
        console.error('Error: Role ID is missing.');
        return;
      }

      try {
        const payload = {
          name: this.roleName.trim() ? this.roleName : undefined, // Include only if not empty
          description: this.roleDescription.trim()
            ? this.roleDescription
            : undefined,
          permissions: this.selectedPermissions,
        };

        // Remove undefined properties from payload
        Object.keys(payload).forEach(
          (key) => payload[key] === undefined && delete payload[key]
        );

        await this.$put({
          url: `${this.$baseurl}/auths/v1/roles/${this.roleId}`,
          data: payload,
          headers: this.headers,
        });

        this.fetchUsers(); // Refresh list
        this.closeModal(); // Close modal
      } catch (error) {
        console.error('Error saving role changes:', error);
        alert('Failed to update role. Please try again.');
      }
    },
  },
  computed: {
    usersUrlWithQuery() {
      return this.searchQuery.trim()
        ? `${this.$baseurl}/auths/v1/roles?q=${encodeURIComponent(
            this.searchQuery
          )}`
        : `${this.$baseurl}/auths/v1/roles`;
    },
  },
  mounted() {
    this.fetchPermissions();
  },
};
</script>

<style scoped>
.modal-enter-active,
.modal-leave-active {
  transition: opacity 0.2s;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
