<template>
  <div class="flex items-center justify-center bg-gray-50 p-5 w-full">
    <div class="w-full max-w-lg bg-white rounded-lg shadow-md p-8">
      <h3 class="text-xl font-bold mb-10 text-center">Complete Admin Setup</h3>

      <form @submit.prevent="submit">
        <template v-if="getFormError(form)">
          <div class="alert alert-red-soft mb-10">
            <span class="alert-icon">!</span>
            <span class="">{{ getFormError(form) }}</span>
          </div>
        </template>

        <form-group
          type="text"
          left-icon="person-outline"
          name="firstName"
          :form="form"
          v-model="form.data.firstName.value"
          :autofocus="true"
        >
          First Name
        </form-group>

        <form-group
          type="text"
          left-icon="person-outline"
          name="lastName"
          :form="form"
          v-model="form.data.lastName.value"
          :autofocus="true"
        >
          Last Name
        </form-group>

        <form-group
          type="email"
          left-icon="mail-outline"
          name="email"
          :form="form"
          v-model="form.data.email.value"
          :disabled="true"
        >
          Email
        </form-group>

        <form-group
          type="password"
          left-icon="lock-closed-outline"
          name="password"
          :form="form"
          v-model="form.data.password.value"
        >
          Password (min 8 characters)
        </form-group>

        {{ this.token }}
        <div class="w-8/10 mx-auto">
          <button
            type="submit"
            class="btn btn-blue btn-md mb-4 w-full"
            :disabled="form.loading"
          >
            <template v-if="form.loading">Setting up account...</template>
            <template v-else>Complete Setup</template>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InviteVerification',
  data() {
    return {
      form: this.$options.basicForm([
        { name: 'firstName', value: '', rules: 'required' },
        { name: 'lastName', value: '', rules: 'required' },
        { name: 'email', value: '', rules: 'required|email' },
        { name: 'password', value: '', rules: 'required|min:8' },
      ]),

      email: '',
      token: '',
    };
  },
  methods: {
    getFormError(form) {
      return form.error;
    },

    async submit() {
      if (!this.validateForm(this.form)) {
        return false;
      }

      try {
        await this.$post({
          url: `${this.$baseurl}/auths/v1/admin/process-invite/${this.email}/${this.token}`,
          headers: this.headers,
          data: {
            firstName: this.form.data.firstName.value,

            lastName: this.form.data.lastName.value,

            password: this.form.data.password.value,
          },
        });

        this.$router.push('/admin/admin');
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    // Pre-fill email and role from URL parameters
    const email = this.$route.params.email || '';
    const token = this.$route.params.verificationToken || '';

    this.form.data.email.value = decodeURIComponent(email);

    this.email = decodeURIComponent(email);
    this.token = token;
  },
};
</script>
