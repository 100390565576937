<template>
  <component :is="appMode"></component>
</template>

<script>
export default {
  name: 'roles',
  components: {
    super: require('./Super.vue').default,
  },
};
</script>
