<template>
  <div class="-mx-10 -mt-8 -mb-32">
    <div class="border-t border-blue-200 flex flex-wrap h-full">
      <div class="w-full md:w-5/10 xl:w-3/10 overflow-y-auto h-app-screen pb-4">
        <div class="p-10 border-b borer-blue-200">
          <search-form
            v-model="staff.query"
            placeholder="Search for staff name, email, phone, bvn etc"
            @submit="reloadUsersTable"
          />
        </div>

        <datatable
          ref="table"
          :ajax="true"
          :ajax-pagination="true"
          :show-headings="false"
          :url="url"
          :limit="20"
          :columns="staff.columns"
          :class-name="tableRowClassName"
          :on-click="selectStaff"
          :query="staff.query"
        >
          <template v-slot:td-1="{ item: { row: s } }">
            <div
              class="text-sm"
              :class="{
                'font-bold': s == staff.selected,
                'font-thin': s !== staff.selected,
              }"
            >
              {{ s.full_name }}
            </div>
            <div class="text-2xs font-thin text-gray-700">
              {{ s | roles }}
            </div>
          </template>
        </datatable>
      </div>

      <div
        class="w-full md:w-5/10 xl:w-7/10 h-app-screen px-10 pt-10 border-l border-blue-200 overflow-y-auto pb-32"
      >
        <template v-if="roles.loading || staff.loading">
          <sm-loader class="mb-10" />
        </template>
        <template v-else-if="staff.selected">
          <div class="text-lg mb-10">
            Update {{ staff.selected.name }}'s roles and permissions
          </div>

          <div class="mb-10">
            <button
              class="btn btn-sm btn-orange-outline mr-3"
              @click="clear"
              :disabled="form.loading || noneSelected"
            >
              Clear All
            </button>
            <button
              class="btn btn-sm btn-gray-outline mr-3"
              @click="reset"
              :disabled="form.loading || !formChanged"
            >
              Undo Changes
            </button>
            <button
              class="btn btn-sm btn-blue"
              @click="updatePermissions"
              :disabled="form.loading || !formChanged"
            >
              <sm-loader
                class="sm-loader-white"
                v-if="form.loading"
              ></sm-loader>
              <span v-else>Save Changes</span>
            </button>
          </div>

          <!-- Roles -->
          <div class="border-b border-blue-200 mb-10 pb-10 -mx-10 px-10">
            <div class="flex items-center flex-wrap mb-10">
              <div class="font-bold">Select Roles</div>
              <div class="flex items-center ml-10">
                <span class="text-xs text-gray-600">Filter by:</span>
                <custom-select
                  class="inline-flex relative text-sm border border-blue-200 rounded-sm pl-3 py-2 pr-3 ml-5"
                  placeholder="Filter Roles"
                  :options="roles.tabs"
                  v-model="roles.tab"
                />
              </div>
            </div>

            <div class="flex items-center flex-wrap mb-5">
              <template v-for="(role, n) in filteredRoles">
                <div
                  class="badge cursor-pointer mb-2 mr-2"
                  :class="roleClass(role)"
                  @click.prevent="toggleRole(role)"
                  :key="n"
                >
                  {{ role.name }}
                </div>
              </template>
            </div>

            <div class="alert alert-blue-soft items-start">
              <span class="alert-icon">!</span>
              <div>
                <div>
                  {{ staff.selected.firstname }} will be assign permissions
                  according to the selected roles!
                </div>
                <div>
                  To assign more specific permissions, you can select and
                  deselect which ever permissions you wish below.
                </div>
              </div>
            </div>
          </div>

          <!-- Permissions -->
          <div class="grid grid-cols-2 gap-8">
            <div class="col-span-1">
              <div class="mb-10">
                <div class="font-bold mb-4">All Permissions</div>
                <search-form
                  class="flex-grow"
                  v-model="permissions.queryAll"
                  placeholder="Search all permissions"
                />
              </div>

              <template v-if="filteredPermissions.length">
                <template v-for="(permission, n) in filteredPermissions">
                  <div
                    class="badge px-3 py-2 text-xs cursor-pointer mb-2 mr-2"
                    :class="permissionClass(permission)"
                    @click.prevent="togglePermission(permission)"
                    :key="n"
                  >
                    {{ permission.slug }}
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="text-sm">No permissions found</div>
              </template>
            </div>
            <div class="col-span-1">
              <div class="mb-10">
                <div class="font-bold mb-4">Assigned Permissions</div>
                <search-form
                  class="flex-grow"
                  v-model="permissions.queryAssigned"
                  placeholder="Search assigned permissions"
                />
              </div>

              <template v-if="filteredAssignedPermissions.length">
                <template
                  v-for="(permission, n) in filteredAssignedPermissions"
                >
                  <div
                    class="badge px-3 py-2 text-xs cursor-pointer mb-2 mr-2"
                    :class="permissionClass(permission)"
                    @click.prevent="togglePermission(permission)"
                    :key="n"
                  >
                    {{ permission.slug }}
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="text-sm">No permissions found</div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="text-sm text-gray-700">
            Click on a staff member to view and update their roles and
            permissions.
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SuperAdminPermissions',
  data() {
    return {
      staff: this.$options.resource([], {
        query: '',
        selected: null,
        columns: [{ name: 'name', th: 'Name' }],
      }),
      roles: this.$options.resource([], {
        tab: 'all',
        tabs: [
          { value: 'all', title: 'All Roles' },
          { value: 'admin', title: 'CredPal Admin Roles' },
          { value: 'company', title: 'Company Admin Roles' },
          { value: 'staff', title: 'Staff Roles' },
        ],
        query: '',
      }),
      permissions: this.$options.resource([], {
        queryAll: '',
        queryAssigned: '',
      }),
      form: this.$options.basicForm([
        { name: 'roles', value: [] },
        { name: 'permissions', value: [] },
      ]),
    };
  },
  computed: {
    filteredStaff() {
      return this.staff.data.filter((staff) => {
        const regexp = new RegExp(this.staff.query, 'i');
        return (
          staff.name?.match(regexp) ||
          staff.last_name?.match(regexp) ||
          staff.email?.match(regexp) ||
          staff.phone?.match(regexp) ||
          staff.profile?.bvn?.match(regexp)
        );
      });
    },
    credpalAdminRoles() {
      return this.roles.data.filter((role) => role.slug.match(/^super_/));
    },
    companyAdminRoles() {
      return this.roles.data.filter(
        (role) =>
          role.slug.match(/^company_/) &&
          !role.slug.match(/^company_(staff|department_head|unit_head)$/)
      );
    },
    staffRoles() {
      return this.roles.data.filter((role) =>
        role.slug.match(/^company_(staff|department_head|unit_head)$/)
      );
    },
    credpalAdminPermissions() {
      return this.permissions.data.filter((permission) =>
        permission.slug.match(/^credpal:/)
      );
    },
    companyAdminPermissions() {
      return this.permissions.data.filter((permission) =>
        permission.slug.match(/^company:/)
      );
    },
    staffPermissions() {
      return this.permissions.data.filter((permission) =>
        permission.slug.match(/^personal:/)
      );
    },
    filteredRoles() {
      switch (this.roles.tab) {
        case 'admin':
          return this.credpalAdminRoles;
        case 'company':
          return this.companyAdminRoles;
        case 'staff':
          return this.staffRoles;
        case 'all':
        default:
          return this.roles.data;
      }
    },
    allPermissions() {
      return [
        ...this.credpalAdminPermissions,
        ...this.companyAdminPermissions,
        ...this.staffPermissions,
      ];
    },
    filteredPermissions() {
      const regexp = new RegExp(this.permissions.queryAll, 'i');
      return this.allPermissions.filter((permission) =>
        permission.slug.match(regexp)
      );
    },
    assignedPermissions() {
      return this.allPermissions.filter((permission) =>
        this.isPermissionSelected(permission)
      );
    },
    filteredAssignedPermissions() {
      const regexp = new RegExp(this.permissions.queryAssigned, 'i');
      return this.assignedPermissions.filter((permission) =>
        permission.slug.match(regexp)
      );
    },
    noneSelected() {
      return !this.roleSelected && !this.permissionsSelected;
    },
    formChanged() {
      return this.rolesChanged || this.permissionsChanged;
    },
    rolesChanged() {
      return (
        JSON.stringify(this.staff.selected?.roles?.map((role) => role.slug)) !==
        JSON.stringify(this.form.data.roles.value)
      );
    },
    permissionsChanged() {
      return (
        JSON.stringify(
          this.staff.selected?.permissions?.map((permission) => permission.slug)
        ) !== JSON.stringify(this.form.data.permissions.value)
      );
    },
    roleSelected() {
      return this.form.data.roles.value?.length;
    },
    permissionsSelected() {
      return this.form.data.permissions.value?.length;
    },
    url() {
      return `${this.$baseurl}/companies/permissions/users/${this.user?.company_id}`;
    },
  },
  filters: {
    roles(staff) {
      return staff?.roles?.map((role) => role.name).join(', ');
    },
  },
  mounted() {
    // this.getStaff();
    this.getRoles();
    this.getPermissions();
  },
  methods: {
    selectStaff(staff) {
      this.staff.selected = staff;
      this.reset();
      this.$refs.table.renderData();
    },
    roleClass(role) {
      if (this.isRoleSelected(role)) {
        return 'badge-blue';
      }

      return 'badge-blue-outline';
    },
    isRoleSelected(role) {
      return this.form.data.roles.value?.find((r) => r == role.slug);
    },
    tableRowClassName(user, index, row) {
      return row.selected || user === this.staff.selected ? 'selected' : '';
    },
    toggleRole(role) {
      if (this.isRoleSelected(role)) {
        this.form.data.roles.value = this.form.data.roles.value?.filter(
          (r) => r != role.slug
        );
      } else {
        if (!Array.isArray(this.form.data.roles.value)) {
          this.form.data.roles.value = [];
        }
        this.form.data.roles.value.push(role.slug);
      }
      this.determinePermissions();
    },
    isPermissionSelected(permission) {
      return this.form.data.permissions.value?.find(
        (r) => r == permission.slug
      );
    },
    permissionClass(permission) {
      var color = 'green';

      if (permission.slug.match(/^company:/)) {
        color = 'blue';
      }

      if (permission.slug.match(/^personal:/)) {
        color = 'red';
      }

      if (this.isPermissionSelected(permission)) {
        return `badge-${color}`;
      }

      return `badge-${color}-outline`;
    },
    togglePermission(permission) {
      if (this.isPermissionSelected(permission)) {
        this.form.data.permissions.value =
          this.form.data.permissions.value?.filter((r) => r != permission.slug);
      } else {
        this.form.data.permissions.value.push(permission.slug);
      }
    },
    determinePermissions() {
      var permissions = [];
      const roles = this.roles.data.filter((role) => this.isRoleSelected(role));

      roles.forEach((role) => {
        permissions = [
          ...permissions,
          ...role.permissions.map((permission) => permission.slug),
        ];
      });

      this.form.data.permissions.value = permissions;
    },
    clear() {
      this.form.data.roles.value = [];
      this.form.data.permissions.value = [];
    },
    reloadUsersTable() {
      this.$refs.table.loadAjaxData();
    },
    reset() {
      this.form.data.roles.value = this.staff.selected?.roles?.map(
        (role) => role.slug
      );
      this.form.data.permissions.value = this.staff.selected?.permissions?.map(
        (permission) => permission.slug
      );
    },
    async getStaff() {
      this.staff.loading = true;

      await this.sendRequest('corporate.permissions.users', {
        success: (response) => {
          this.staff.data = response.data.data;

          if (this.staff.selected) {
            this.staff.selected = this.staff.data.find(
              (staff) => staff.id == this.staff.selected.id
            );
            this.reset();
          }
        },
        error: (error) => {
          this.staff.error = error;
        },
      });

      this.staff.loading = false;
    },
    async getRoles() {
      this.roles.loading = true;

      await this.sendRequest('admin.roles.all', {
        success: (response) => {
          this.roles.data = response.data.data;
        },
        error: (error) => {
          this.roles.error = error;
        },
      });

      this.roles.loading = false;
    },
    async getPermissions() {
      this.permissions.loading = true;

      await this.sendRequest('admin.permissions.all', {
        success: (response) => {
          this.permissions.data = response.data.permissions;
        },
        error: (error) => {
          this.permissions.error = error;
        },
      });

      this.permissions.loading = false;
    },
    async updatePermissions() {
      this.form.loading = true;
      this.form.error = false;

      await this.sendRequest('corporate.permissions.update', {
        data: {
          ...this.getFormData(this.form),
          user_id: this.staff.selected?.id,
        },
        success: () => {
          this.reloadUsersTable();
        },
        error: (error) => {
          this.form.error = error;
        },
      });

      this.form.loading = false;
    },
  },
};
</script>
